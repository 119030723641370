$(document).ready(function() {
    if (navigator.userAgent.match(/768/i))
        $('button.navbar-toggler.d-lg-none:first').attr("style", "display: block !important");
});

$(document).on('click', "app-sidebar-nav-link.menu", function(e) {
    callAngularFunction();
    $("app-sidebar-nav-dropdown.nav-item.nav-dropdown.menu").removeClass('open');
});

$(document).on('click', 'app-sidebar-nav-dropdown.menu', function(e) {
    $("app-sidebar-nav-dropdown.menu").not(this).removeClass('open');
});

$(document).on('click', 'app-sidebar-nav-dropdown .sub-menu', function(e) {
    callAngularFunction();
    $(this).closest('app-sidebar-nav-dropdown').addClass('open');
});

$('body').on('keydown', 'input,textarea', function(e) {
    if (e.which === 32 && e.target.selectionStart === 0) {
        return false;
    }
});
$(document).on('click', '.view_img', function() {
    $(this).closest('.img_preview_div').next('.img-box').css({ "transform": "scale(1)", "transition": "all 0.4s ease-in 0s", });
});

$(document).on('click', '.close_btn', function() {
    $('.img-box').css({ "transform": "scale(0)", "transition": "all 0.4s ease-in 0s", });
});

$(document).on('keydown', 'textarea#chatInput', function(e) {
    if (e.keyCode == 13 && !e.shiftKey) {
        // prevent default behavior
        e.preventDefault();
    }
});

$(document).on('click', '.demo', function() {
    $(this).next('.order_list_hide').toggleClass('order_list_show');
});

$(document).on('click', '.replay', function() {
    $(this).next('#replay').toggleClass('show_msg');
});

$(document).on('click', '.view_more', function() {
    var _this = $(this);
    _this.next('.review_Grp').slideToggle('slow', function() {
        _this.toggleClass('arrow_up');
    });
});

$(document).on('click', '.show_details', function() {
    $(this).closest('#view_detail').next('.view_details').toggleClass('view_fleet_det');
});


$(function() {

    var defaultselectbox = $('#cusSelectbox');
    var numOfOptions = $('#cusSelectbox').children('option').length;

    // hide select tag
    defaultselectbox.addClass('s-hidden');

    // wrapping default selectbox into custom select block
    defaultselectbox.wrap('<div class="cusSelBlock"></div>');

    // creating custom select div
    defaultselectbox.after('<div class="selectLabel"></div>');

    // getting default select box selected value
    $('.selectLabel').text(defaultselectbox.children('option').eq(0).text());

    // appending options to custom un-ordered list tag
    var cusList = $('<ul/>', { 'class': 'options' }).insertAfter($('.selectLabel'));

    // generating custom list items
    for (var i = 0; i < numOfOptions; i++) {
        $('<li/>', {
            text: defaultselectbox.children('option').eq(i).text(),
            rel: defaultselectbox.children('option').eq(i).val()
        }).appendTo(cusList);
    }

    // open-list and close-list items functions
    function openList() {
        for (var i = 0; i < numOfOptions; i++) {
            $('.options').children('li').eq(i).attr('tabindex', i).css(
                'transform', 'translateY(' + (i * 100 + 100) + '%)').css(
                'transition-delay', i * 30 + 'ms');
        }
    }

    function closeList() {
        for (var i = 0; i < numOfOptions; i++) {
            $('.options').children('li').eq(i).css(
                'transform', 'translateY(' + i * 0 + 'px)').css('transition-delay', i * 0 + 'ms');
        }
        $('.options').children('li').eq(1).css('transform', 'translateY(' + 2 + 'px)');
        $('.options').children('li').eq(2).css('transform', 'translateY(' + 4 + 'px)');
    }

    // click event functions
    $('.selectLabel').click(function() {
        $(this).toggleClass('active');
        if ($(this).hasClass('active')) {
            openList();
            focusItems();
        } else {
            closeList();
        }
    });

    $(".options li").on('keypress click', function(e) {
        e.preventDefault();
        $('.options li').siblings().removeClass();
        closeList();
        $('.selectLabel').removeClass('active');
        $('.selectLabel').text($(this).text());
        defaultselectbox.val($(this).text());
        $('.selected-item p span').text($('.selectLabel').text());
    });

});

$(function() {

    var defaultselectbox = $('#cusSelectbox');
    var numOfOptions = $('#cusSelectbox').children('option').length;

    // hide select tag
    defaultselectbox.addClass('s-hidden');

    // wrapping default selectbox into custom select block
    defaultselectbox.wrap('<div class="cusSelBlock"></div>');

    // creating custom select div
    defaultselectbox.after('<div class="selectLabel"></div>');

    // getting default select box selected value
    $('.selectLabel').text(defaultselectbox.children('option').eq(0).text());

    // appending options to custom un-ordered list tag
    var cusList = $('<ul/>', { 'class': 'options' }).insertAfter($('.selectLabel'));

    // generating custom list items
    for (var i = 0; i < numOfOptions; i++) {
        $('<li/>', {
            text: defaultselectbox.children('option').eq(i).text(),
            rel: defaultselectbox.children('option').eq(i).val()
        }).appendTo(cusList);
    }

    // open-list and close-list items functions
    function openList() {
        for (var i = 0; i < numOfOptions; i++) {
            $('.options').children('li').eq(i).attr('tabindex', i).css(
                'transform', 'translateY(' + (i * 100 + 100) + '%)').css(
                'transition-delay', i * 30 + 'ms');
        }
    }

    function closeList() {
        for (var i = 0; i < numOfOptions; i++) {
            $('.options').children('li').eq(i).css(
                'transform', 'translateY(' + i * 0 + 'px)').css('transition-delay', i * 0 + 'ms');
        }
        $('.options').children('li').eq(1).css('transform', 'translateY(' + 2 + 'px)');
        $('.options').children('li').eq(2).css('transform', 'translateY(' + 4 + 'px)');
    }

    // click event functions
    $('.selectLabel').click(function() {
        $(this).toggleClass('active');
        if ($(this).hasClass('active')) {
            openList();
            focusItems();
        } else {
            closeList();
        }
    });

    $(".options li").on('keypress click', function(e) {
        e.preventDefault();
        $('.options li').siblings().removeClass();
        closeList();
        $('.selectLabel').removeClass('active');
        $('.selectLabel').text($(this).text());
        defaultselectbox.val($(this).text());
        $('.selected-item p span').text($('.selectLabel').text());
    });

});

$(function() {

    var defaultselectbox = $('#cusSelectbox');
    var numOfOptions = $('#cusSelectbox').children('option').length;

    // hide select tag
    defaultselectbox.addClass('s-hidden');

    // wrapping default selectbox into custom select block
    defaultselectbox.wrap('<div class="cusSelBlock"></div>');

    // creating custom select div
    defaultselectbox.after('<div class="selectLabel"></div>');

    // getting default select box selected value
    $('.selectLabel').text(defaultselectbox.children('option').eq(0).text());

    // appending options to custom un-ordered list tag
    var cusList = $('<ul/>', { 'class': 'options' }).insertAfter($('.selectLabel'));

    // generating custom list items
    for (var i = 0; i < numOfOptions; i++) {
        $('<li/>', {
            text: defaultselectbox.children('option').eq(i).text(),
            rel: defaultselectbox.children('option').eq(i).val()
        }).appendTo(cusList);
    }

    // open-list and close-list items functions
    function openList() {
        for (var i = 0; i < numOfOptions; i++) {
            $('.options').children('li').eq(i).attr('tabindex', i).css(
                'transform', 'translateY(' + (i * 100 + 100) + '%)').css(
                'transition-delay', i * 30 + 'ms');
        }
    }

    function closeList() {
        for (var i = 0; i < numOfOptions; i++) {
            $('.options').children('li').eq(i).css(
                'transform', 'translateY(' + i * 0 + 'px)').css('transition-delay', i * 0 + 'ms');
        }
        $('.options').children('li').eq(1).css('transform', 'translateY(' + 2 + 'px)');
        $('.options').children('li').eq(2).css('transform', 'translateY(' + 4 + 'px)');
    }
});

$(document).on("click", ".accordion a", function() {
    $(this).toggleClass('active');
    $(this).next('.content').slideToggle(400);
});


$(document).on("click", ".cselect", function() {
    $('.cselect').not(this).find('ul').hide();
    var $input = $(this).find("input");
    var $dropDown = $(this).find("ul");
    $dropDown.stop().slideToggle();
});

$(document).on("click", ".toggle-password", function() {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
        input.attr("type", "text");
    } else {
        input.attr("type", "password");
    }
});